.grecaptcha-badge {
  visibility: hidden;
}

/* animation style  */
.flyingBtn {
  z-index: 1;
  transform-origin: center bottom;
  transform: scale(1);
}

#header-shop #cart button {
  font-size: 1.5rem;
  height: 100%;
  background: rgba(0, 0, 0, 0.2);
  width: 2em;
  border-radius: 5px;
  outline: none;
  position: relative;
  -webkit-box-shadow: 0px 6px 3px -3px rgba(0, 0, 0, 0.2) inset;
  -moz-box-shadow: 0px 6px 3px -3px rgba(0, 0, 0, 0.2) inset;
  box-shadow: 0px 6px 3px -3px rgba(0, 0, 0, 0.2) inset;
}

#header-shop #cart button[data-count]::after {
  content: attr(data-count);
  display: block;
  position: absolute;
  font-size: 0.7rem;
  font-weight: bold;
  border-radius: 100px;
  padding: 0 0.25em;
  right: 5%;
  bottom: 5%;
}

#header-shop #cart .addedCount::after {
  animation-name: shakeUp;
  animation-duration: 0.3s;
  animation-fill-mode: forwards;
  animation-timing-function: ease;
}

@keyframes shakeUp {
  0% {
    opacity: 0;
    transform: translateY(100%);
  }
  50% {
    transform: translateY(-50%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes shakeCart {
  25% {
    transform:translateX(6px)
  }
  50% {
    transform:translateX(-4px);
  }
  75% {
    transform:translateX(2px);
  }
  100% {
    transform:translateX(0);
  }
}

[data-addToCart] {
  border: none;
  color: white;
  outline: none;
}

[data-addToCart]:hover {
  cursor: pointer;
}

.shake {
  animation: shakeCart .4s ease-in-out forwards;
}

.adyen-checkout__payment-method {
  font-size: 15px !important;
}