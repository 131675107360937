html {
  font-size: 10px;
}

body {
  margin: 0;
  font-family: Montserrat;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  overflow-y: auto;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

p,
span,
h1,
h2,
h3,
h4,
h5,
div,
h6 {
  overflow-wrap: anywhere !important;
}
